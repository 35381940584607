$header-color 		: #30297d;
$header-background  : #e0e0e0;
$header-color-sub 	: #222;
$detail-background 	: #f8f8f8;


*:focus {
    outline: none;
}

body
{
	font-family : sans-serif;
	background-color : #f8f8f8;
	padding 		 : 1em;
	margin   		 : 0;
}




.session, .list-entry
{
	max-width 			: 1100px;
	margin 				: 2rem auto 0em auto;
	background-color 	: $detail-background;
	box-shadow 			: 0px 2px 5px #d0d0d0;

	.body
	{
		padding 	: 0.8em;
		overflow 	: hidden;
		display 	: flex;
		flex-flow 	: row nowrap;

		.details
		{
			flex-grow 			: 1;
			margin-right 		: 1em;

			.paper
			{
				font-size 		: 0.9em;
				color 			: #444;
				line-height 	: 1.3em;
				padding-bottom 	: 1em;
			}

			.playbutton
			{
				font-size 		: 0.8em;
				font-weight 	: bold;
				color 			: $header-color;
				margin-bottom 	: 0.4em;
				cursor 			: pointer;
			}
		}

		.preview-container
		{
			min-width 		: 350px;
			flex-shrink 	: 0;
			flex-grow 		: 1;
		}

		.preview
		{
			position 		: relative;
			float 			: right;

			img
			{
				height 		 : 200px;
				border 		 : 1px solid #ddd;
    			margin-left  : auto;
    			margin-right : auto;
    			display 	: block;
    			opacity 	: 0.8;
    		}

			.novideo
			{
				background-color  	: #ddd;
				height 		: 60px;
				font-size 	: 0.8em;
				padding 	: 1em 0.5em;
				color 		: #888;
				text-align 	: center;
			}

			.playbutton
			{
				position 		: absolute;
				left 			: calc( 50% - 40px );
				top 			: calc( 50% - 40px );
				width 			: 80px;
				height 			: 80px;
				opacity 		: 0.3;
				mix-blend-mode 	:  multiply;
			}
		}

		.droptarget
		{
			display 	: flex;
			align-items : stretch;

			min-width 			: 15em;
			flex-grow 			: 1;
			cursor 				: pointer;
			background-color  	: #f0f0f0;
			font-weight 		: 200;
			margin-right 		: 2em;
			padding 			: 1.5em 1em;

			overflow-x 			: hidden;
			overflow-y 			: auto;

			.error
			{
				color 			: indianred;
				margin-bottom 	: 1em;
				font-weight 	: bold;
			}
		}

		.droptarget:hover
		{
			opacity 			: 1;
			transition 			: 300ms;
			box-shadow 			: inset 2px 2px 5px #888;
		}

	}


	.header
	{
		padding 			: 0.8em;
		background   		: $header-background;
		border-bottom 	    : 1px solid white;
		position 			: relative;


		.backbutton
		{
			display 		: inline-block;
			float 			: right;
			position 		: absolute;
			top 			: 1em;
			right 			: 1em;
			width 			: 1em;
			height 			: 1em;

		}

		.title
		{
			font-size 		: 1.6rem;
			color 			: $header-color;
			margin-bottom 	: 0.4em;
			margin-right 	: 4em;
			font-weight 	: bold;
			cursor 			: pointer;
		}

		.title:hover
		{
			opacity 		: 0.5;
		}

		.details
		{
			color 			: $header-color-sub;
			opacity 		: 0.8;
			font-size 		: 0.9rem;
			margin-top 		: 1em;
			display 		: flex;
			flex-flow 		: row wrap;

			.speaker
			{
				font-weight 	: bold;
				flex-grow 		: 3;
				margin-bottom 	: 1em;
			}

			.category
			{
				font-style 	: italic;
				flex-grow 	: 1;
				text-align 	: right;
			}
		}
	}
}


.list-viewer
{
	.list
	{
		margin 		: 1rem 8rem 2rem 0rem;
		padding 	: 0;
		font-size 	: 1rem;

		a
		{
			margin-bottom 	: 1em;
			text-decoration : none;
			color 			: #444;

			.title
			{
				color 			: $header-color;
				margin-bottom 	: 0.2em;
			}

			.title:hover, .speaker:hover
			{
				opacity 			: 0.8;
			}

			.speaker
			{
				font-style 		: italic;
				color 			: #888;
				font-size 		: 0.7em;
				margin-bottom 	: 0.8em;
			}
		}
	}
}


.presentations
{
	position 	: relative;
	opacity 	: 1;
	transition  : 100ms;

	padding-right : 2em;
	overflow-x  : hidden;
	overflow-y  : auto;
}

.presentations.playing
{
	opacity 	: 0.2 !important;
	transition  : 100ms;
}



.list-viewer .videoplayer .player
{
	max-width 			: 90%;
	max-height 			: 90%;
}

.main-viewer .videoplayer .player
{
	max-width 			: 60%;
	max-height 			: 80%;
}

.videoplayer
{
	position 		: fixed;
	right 			: 0px;
	bottom 			: 0px;
	left 			: 0px;
	top 			: 0px;
	z-index 		: 2000;
	display 		: flex;
  	align-items 	: center;
  	justify-content : center;

	.background
	{
		position 			: absolute;
		background-color 	: #f0f0f0;
		opacity 			: 95%;
		right 				: 0px;
		bottom 				: 0px;
		left 				: 0px;
		top 				: 0px;
	}

	.player
	{
		box-shadow 			: 0px 5px 8px #444;
		max-width 			: 75%;
		position 			: relative;
	}

	.header
	{
		height 				: 2em;
		background-color 	: white;

		.close-button
		{
			position 	: absolute;
			left 		: 0.8em;
			top 		: 0.4em;
			height 		: 1.2em;
			width 		: 1.2em;
			text-align 	: center;
			font-size 	: 0.8em;
			color 		: white;
			opacity 	: 0.8;
		}

		.close-button:hover
		{
			opacity 	: 1;
		}

		.label
		{
			position 	: absolute;
			width 		: 8em;
			top 		: 0.8em;
			right 		: 1em;
			height 		: 1.5em;
			text-align 	: right;
			text-transform : capitalize;
		}
	}

	.body
	{
		max-width 		: 100%;

		video
		{
			width 		: 100%;
		}
	}
}
